import { Link } from 'gatsby'
import React, { useState, Fragment, useEffect } from 'react'
import { Container, Navbar, Nav, Row, Col } from 'react-bootstrap'
import { useGetBlogsQuery } from '@apis/mediacore/blog'
import { useGetJobsQuery } from '@apis/mediacore/jobs'
import { RealEstateDataHeaderData, RealEstateDataPage, StatePropertiesSlice } from 'types'
import useQuerySummary from '@hooks/useQuerySummary'
import toast, { Toaster } from 'react-hot-toast'
import { useGetConfigurationsQuery } from '@apis/mediacore/configuration'
import { makeItemsNav } from '@helpers/helper.rendering'
import { getfavorites } from '@helpers/helper.favorites'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from '@reach/router'
import $ from 'jquery'
import { useGetPushQuery } from '@apis/mediacore/push'
import { getActionByType, getCodeFromType } from '@helpers/helper.actions'

const isBrowser = typeof window !== 'undefined'
interface ExternalProps {
  pages: RealEstateDataPage[]
  development_id: string
  property_id: string
  novelty_id: string
}

type CombinedProps = RealEstateDataHeaderData & ExternalProps

const Header1 = (props: CombinedProps) => {
  const {
    isDemo,
    pages,
    global_data,
    development_id,
    property_id,
    novelty_id,
    background_secondary,
    hoverColor,
    fontColor,
  } = props
  const { divisionPath, id: siteId } = global_data
  const API_KEY = global_data.pageConfig.keys.tokko

  const { pathname } = useLocation()

  const isCompiler = process.env.GATSBY_IS_SITE_COMPILER === 'true'

  const currentPage = pages?.find(
    (page) =>
      (pathname.replaceAll('/', '') === page.path.replaceAll('/', '') && !isCompiler) ||
      (isCompiler &&
        pathname !== '/' &&
        pathname.replace(global_data.divisionPath!, '').replaceAll('/', '') ===
          page.path.replaceAll('/', '')) ||
      (pathname.includes('/emprendimiento/') && page.path === '/desarrollo_detalle') ||
      (pathname.includes('/propiedad/') && page.path === '/propiedad_detalle') ||
      (pathname.includes('/novedad/') && page.path === '/novedad_detalle'),
  )

  const { data: allPushData, isLoading: loading } = useGetPushQuery({
    page: currentPage?.id || '',
    data_id: development_id || property_id || novelty_id || '',
  })

  const hasALertTop =
    getActionByType(allPushData, getCodeFromType('Barra Encabezado Timer')) ||
    getActionByType(allPushData, getCodeFromType('Barra Encabezado'))

  const location = useLocation()

  const { titles, isInverted, buttonCompare, buttonHeart } = isDemo ? props : props

  const [open, setOpen] = useState<boolean>(false)

  const { data: allBlogData } = useGetBlogsQuery({ site: siteId })
  const { data: allJobsData } = useGetJobsQuery({ site: siteId })
  const { allSummaryData } = useQuerySummary({ API_KEY })
  const { data: modules } = useGetConfigurationsQuery(process.env.GATSBY_CLIENTID)

  const [listFavorites, setListFavorites] = useState(getfavorites('prop', 'template-site'))

  const { updaterFavorites } = useSelector(
    (state: { properties: StatePropertiesSlice }) => state.properties,
  )!

  const INVESTOR_CONFIGURATION = modules?.find(
    (config: { module: string }) => config.module === 'INVESTOR',
  )
  const OWNER_CONFIGURATION = modules?.find(
    (config: { module: string }) => config.module === 'OWNER',
  )
  const hasModules = (INVESTOR_CONFIGURATION?.module || OWNER_CONFIGURATION?.module)?.length > 0

  const branch_office = global_data.branches
  const contact_data = global_data.contact_data
  const social = global_data.pageConfig.social
  const logo = global_data.logo
  const logoMobile = global_data.logoMobile

  const icons: string = global_data.pageConfig.design.icons
  const iconsStyle = icons === 'Solid' || icons === 'Light' ? `-${icons.toLowerCase()}` : ''
  const countFavorites = listFavorites.length

  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
    var body = $('html, body')
    body.stop().animate({ scrollTop: 0 }, 500, 'swing', function () {})
    scrollTo(0, 0)
  }, [location])

  useEffect(() => {
    if (isBrowser) {
      setListFavorites(getfavorites('prop', 'template-site'))
    }
  }, [updaterFavorites])

  const colorAux = isInverted ? '#fff' : 'var(--primaryColor)'

  useEffect(() => {
    document.documentElement.style.setProperty('--dashedColor', fontColor || colorAux)
    document.documentElement.style.setProperty(
      '--hoverColor',
      hoverColor || 'var(--secondaryColor)',
    )
  }, [])

  const fillFilters = (item: any) => {
    return {
      location: [],
      type: [],
      operation: item,
      environments: '',
      bedrooms: '',
      address: '',
      price: {
        type: '',
        priceMin: '',
        priceMax: '',
      },
      surface: {
        type: '',
        surfaceMin: '',
        surfaceMax: '',
      },
      environments_types: [],
      generals: [],
      services: [],
      specials: [],
    }
  }

  function voidGlobalData() {
    const isEmptyString = (str: string | undefined) => typeof str === 'string' && str.trim() === ''
    const isEmptyArray = (arr: any[] | undefined) => Array.isArray(arr) && arr.every(isEmptyString)

    return (
      isEmptyString(contact_data.email) &&
      isEmptyArray(contact_data.phoneLine) &&
      isEmptyArray(contact_data.phoneMobile)
    )
  }

  return isMounted ? (
    <header
      id="header"
      // className={`a header position-fixed blur ${open ? 'opened' : ''}${
      className={`a fixed-top header blur  ${open ? 'opened' : ''}${
        isInverted ? ' inverted ' : ''
      } ${background_secondary ? ' edited-white-blured' : ''}`}
    >
      <Navbar
        sticky="top"
        className="brand"
      >
        <Container
          fluid
          className="mx-auto justify-content-center"
        >
          <Row className="justify-content-center align-items-center w-100">
            <Col
              xs={3}
              className={'d-flex d-lg-none align-items-center justify-content-lg-center'}
            >
              {countFavorites === 0 ? (
                <span
                  className="hover-like d-flex d-lg-none align-items-center"
                  onClick={() => toast.error('No tienes propiedades favoritas seleccionadas.')}
                >
                  <i
                    className={
                      `me-4 ms-2 icon-favoritos-heart${iconsStyle} ` +
                      (countFavorites < 1 && ' disabled ')
                    }
                  ></i>
                </span>
              ) : (
                <div className={'hover-like cursor-pointer d-flex d-lg-none align-items-center '}>
                  <span
                    id="count_favorites"
                    className={'ms-lg-3 d-flex align-items-center ' + ' animate__animated '}
                  >
                    {countFavorites === 1 ? (
                      <span
                        onClick={() =>
                          toast.error(
                            'Tienes que tener al menos 2 propiedades en favoritos para poder comparar.',
                          )
                        }
                        className={'hover-like disabled d-flex d-lg-none align-items-center '}
                      ></span>
                    ) : (
                      <Link
                        to={buttonCompare?.link!}
                        className={'hover-like d-flex d-lg-none align-items-center '}
                      ></Link>
                    )}
                    <Link to={buttonHeart?.link!}>
                      <i className={`me-3 ms-0 icon-favoritos-heart${iconsStyle} `}></i>
                    </Link>
                    {countFavorites}
                  </span>
                </div>
              )}
            </Col>
            <Col
              lg={8}
              xs={6}
              className="justify-content-lg-start justify-content-center"
            >
              <Link
                aria-label="Enlace inicio"
                className="d-lg-block d-flex align-items-center justify-content-center m-0"
                to="/"
              >
                <img
                  alt="logo-header"
                  src={logoMobile}
                  height={'75'}
                  width={'100'}
                  className="d-lg-none d-block align-top m-auto logo-header"
                />
                <img
                  alt="logo-header"
                  src={logo}
                  height={'55'}
                  width={'150'}
                  className="d-lg-inline-block d-none align-top me-2 logo-header"
                />
              </Link>
            </Col>
            <Col
              xs={3}
              lg={4}
              className="text-right d-flex align-items-center justify-content-end"
            >
              <Toaster></Toaster>
              {countFavorites === 0 ? (
                <span className="hover-like d-lg-flex d-none align-items-center">
                  <div
                    onClick={() =>
                      toast.error(
                        'Tienes que tener al menos 2 propiedades en favoritos para poder comparar.',
                      )
                    }
                  >
                    {buttonCompare?.value}
                  </div>
                  <i
                    onClick={() => toast.error('No tienes propiedades favoritas seleccionadas.')}
                    className={
                      `me-4 ms-2 icon-favoritos-heart${iconsStyle} ` +
                      (countFavorites < 1 && ' disabled ')
                    }
                  ></i>
                </span>
              ) : (
                <div className={'hover-like cursor-pointer d-lg-flex d-none align-items-center '}>
                  <span
                    id="count_favorites"
                    className={'ms-lg-3 d-flex align-items-center ' + ' animate__animated '}
                  >
                    {countFavorites === 1 ? (
                      <span
                        onClick={() =>
                          toast.error(
                            'Tienes que tener al menos 2 propiedades en favoritos para poder comparar.',
                          )
                        }
                        className={'hover-like disabled d-lg-flex d-none align-items-center '}
                      >
                        {buttonCompare?.value}
                      </span>
                    ) : (
                      <Link
                        to={buttonCompare?.link!}
                        className={'hover-like d-lg-flex d-none align-items-center '}
                      >
                        {buttonCompare?.value}
                      </Link>
                    )}
                    <div className="ms-4"></div>
                    {countFavorites}
                    <Link to={buttonHeart?.link!}>
                      <i className={`me-4 ms-2 icon-favoritos-heart${iconsStyle} `}></i>
                    </Link>
                  </span>
                </div>
              )}
              <button
                id="toggle-nav"
                aria-label="toggle"
                onClick={() => setOpen(!open)}
              >
                <div className="d-flex align-items-center hover-like">
                  <span className="status_nav d-none d-lg-inline">MENÚ </span>
                  <span className="ms-4">
                    <i></i>
                    <i></i>
                    <i></i>
                  </span>
                </div>
              </button>
            </Col>
          </Row>
          <div className="nav-content opened d-flex align-items-center">
            <Container fluid>
              <Row>
                <Col
                  lg={8}
                  className="pe-xl-5"
                  style={{ alignSelf: 'center' }}
                >
                  <Nav className="nav-menu d-flex flex-column d-lg-block">
                    {makeItemsNav(allSummaryData, allBlogData, pages, allJobsData)
                      .filter((page) => !page.path.includes('detalle'))
                      .map(({ path, title }, index) => (
                        <Fragment key={index}>
                          <Nav.Item>
                            {path.includes('venta') ||
                            path.includes('alquiler') ||
                            (path.includes('temporario') && !location.pathname.includes(path)) ? (
                              <a
                                className={
                                  'text-uppercase ' +
                                  ((location.pathname.includes(path) &&
                                    location.pathname !== '' &&
                                    path !== '/') ||
                                  (path === '/' && location.pathname === '/') ||
                                  (location.pathname.toLowerCase() ===
                                    divisionPath?.toLowerCase() + '/' &&
                                    path === '/')
                                    ? 'active'
                                    : '')
                                }
                                href={(divisionPath ?? '') + path}
                              >
                                {title}
                                <span>
                                  {index < 9 ? '0' : ''}
                                  {index + 1}
                                </span>
                              </a>
                            ) : (
                              <Link
                                onClick={() => {
                                  setOpen(false)
                                  scrollTo(0, 0)
                                }}
                                className={
                                  'text-uppercase ' +
                                  ((location.pathname.includes(path) &&
                                    location.pathname !== '' &&
                                    path !== '/') ||
                                  (path === '/' && location.pathname === '/') ||
                                  (location.pathname.toLowerCase() ===
                                    divisionPath?.toLowerCase() + '/' &&
                                    path === '/')
                                    ? 'active'
                                    : '')
                                }
                                to={(divisionPath ?? '') + path}
                              >
                                {title}
                                <span>
                                  {index < 9 ? '0' : ''}
                                  {index + 1}
                                </span>
                              </Link>
                            )}
                          </Nav.Item>
                        </Fragment>
                      ))}
                  </Nav>
                  <div
                    className={
                      'item-contact row mb-0 button-separator text-center private-access-container ' +
                      (INVESTOR_CONFIGURATION?.module || OWNER_CONFIGURATION?.module
                        ? 'd-block d-lg-none'
                        : ' d-none')
                    }
                  >
                    <h5 className="private-access">ACCESOS PRIVADOS</h5>
                    {INVESTOR_CONFIGURATION?.module && (
                      <div className="d-block">
                        <a
                          className="btn btn-investor mb-2 d-block d-lg-inline-block"
                          target={'_blank'}
                          href={INVESTOR_CONFIGURATION.url_login}
                        >
                          {INVESTOR_CONFIGURATION.text_cta || 'INVERSORES'}
                        </a>
                      </div>
                    )}
                    {OWNER_CONFIGURATION?.module && (
                      <div className="d-block">
                        <a
                          className="btn btn-investor mb-2 d-block d-lg-inline-block"
                          target={'_blank'}
                          href={OWNER_CONFIGURATION.url_login}
                        >
                          {OWNER_CONFIGURATION.text_cta || 'PROPIETARIOS'}
                        </a>
                      </div>
                    )}
                  </div>
                </Col>
                <Col
                  lg={4}
                  className="px-xl-5 contact-nav d-none d-lg-flex align-items-center"
                >
                  <Row>
                    <div className="max-heigth-scroll">
                      <Col
                        xs={8}
                        lg={12}
                        className="item-contact mb-lg-5"
                      >
                        <h5 className={voidGlobalData() ? 'd-none' : ''}>{titles?.global}</h5>
                        {contact_data?.email! && (
                          <a
                            className="hover-service-link mb-lg-1 "
                            target="_blank"
                            href={'mailto:' + contact_data?.email}
                          >
                            {contact_data.email}
                          </a>
                        )}
                        {contact_data.phoneLine?.map((phone, index) => (
                          <p
                            key={index}
                            className={'mb-0 mb-lg-1 ' + (phone === '' ? 'd-none' : '')}
                          >
                            Teléfono {phone}
                          </p>
                        ))}
                        {global_data.college ? (
                          <p className="mb-0 mb-lg-1 ">
                            {global_data.college + ' ' + global_data.registration_number}
                          </p>
                        ) : (
                          ''
                        )}
                      </Col>
                      <Col xs={12}>
                        {branch_office.length > 0 &&
                          branch_office?.map((item, index) => (
                            <Col
                              xs={12}
                              key={index}
                              className="item-contact mb-lg-5 mt-lg-4 d-none d-lg-block"
                            >
                              <h5>
                                {titles?.branch_office} {item.name}
                              </h5>
                              <p>{item.address + ', ' + item.city}</p>
                              <p>{item.province}, Argentina</p>
                              {item.contact_data.emails.map((mail, index) => (
                                <a
                                  key={index}
                                  target="_blank"
                                  className={'hover-link ' + (!mail && 'd-none')}
                                  href={'mailto:' + mail}
                                >
                                  {mail}
                                </a>
                              ))}
                              {item.contact_data.phonesLine.map((phone, index) => (
                                <p key={index}>Teléfono {phone}</p>
                              ))}
                              {item.contact_data.phonesMobile.map((whatsapp, index) => (
                                <a
                                  key={index}
                                  className={'hover-footer-link'}
                                  target="_blank"
                                  href={
                                    'https://api.whatsapp.com/send?' +
                                    'phone=54' +
                                    whatsapp.replaceAll('-', '')
                                  }
                                >
                                  Whatsapp + {whatsapp}
                                </a>
                              ))}
                              <p className={item.registration_number === '' ? 'd-none' : ''}>
                                Matrícula {item.college + ' ' + item.registration_number}
                              </p>
                            </Col>
                          ))}
                      </Col>
                      <Col
                        lg={12}
                        xs={4}
                        className="item-contact social mb-lg-5"
                      >
                        <h5>{titles?.social}</h5>
                        {social?.instagram && (
                          <a
                            target="_blank"
                            aria-label="Lea mas en la red social instagram"
                            href={social?.instagram}
                            className={social?.instagram ? 'd-inline' : 'd-none'}
                          >
                            <i className={`icon-instagram${iconsStyle}`}></i>
                          </a>
                        )}
                        {social?.facebook && (
                          <a
                            target="_blank"
                            aria-label="Lea mas en la red social facebook"
                            href={social?.facebook}
                            className={social?.facebook ? 'd-inline' : 'd-none'}
                          >
                            <i className={`icon-facebook${iconsStyle}`}></i>
                          </a>
                        )}
                        {social?.linkedin && (
                          <a
                            target="_blank"
                            aria-label="Lea mas en la red social linkedin"
                            href={social?.linkedin}
                            className={social?.linkedin ? 'd-inline' : 'd-none'}
                          >
                            <i className={`icon-linkedin${iconsStyle}`}></i>
                          </a>
                        )}
                        {social?.youtube && (
                          <a
                            target="_blank"
                            aria-label="Lea mas en la red social youtube"
                            href={social?.youtube}
                            className={social?.youtube ? 'd-inline' : 'd-none'}
                          >
                            <i className={`icon-youtube${iconsStyle}`}></i>
                          </a>
                        )}
                        {social?.twitter && (
                          <a
                            target="_blank"
                            aria-label="Lea mas en la red social twitter"
                            href={social?.twitter}
                            className={social?.twitter ? 'd-inline' : 'd-none'}
                          >
                            <i className={`icon-twitter${iconsStyle}`}></i>
                          </a>
                        )}
                        {social?.tiktok && (
                          <a
                            target="_blank"
                            aria-label="Lea mas en la red social tiktok"
                            href={social?.tiktok}
                            className={social?.tiktok ? 'd-inline' : 'd-none'}
                          >
                            <i className={`icon-tiktok${iconsStyle}`}></i>
                          </a>
                        )}
                      </Col>
                    </div>
                    {hasModules ? (
                      <Col
                        className={
                          'item-contact mb-0 button-separator ' +
                          (INVESTOR_CONFIGURATION?.module || OWNER_CONFIGURATION?.module
                            ? 'd-block'
                            : ' d-none')
                        }
                      >
                        <h5 className="">ACCESOS PRIVADOS</h5>
                        {INVESTOR_CONFIGURATION?.module && (
                          <div className="d-block">
                            <a
                              className="btn text-white btn-investor mb-2 d-block d-lg-inline-block"
                              target={'_blank'}
                              href={INVESTOR_CONFIGURATION.url_login}
                            >
                              {INVESTOR_CONFIGURATION.text_cta || 'INVERSORES'}
                            </a>
                          </div>
                        )}
                        {OWNER_CONFIGURATION?.module && (
                          <div className="d-block">
                            <a
                              className="btn text-white btn-investor mb-2 d-block d-lg-inline-block"
                              target={'_blank'}
                              href={OWNER_CONFIGURATION.url_login}
                            >
                              {OWNER_CONFIGURATION.text_cta || 'PROPIETARIOS'}
                            </a>
                          </div>
                        )}
                      </Col>
                    ) : null}
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </Navbar>
    </header>
  ) : (
    ''
  )
}

export default Header1
